import React from "react"
import { Layout } from "../../components/Layout"
import { Citation } from "../../components/Citation"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import {
  Top,
  Left,
  Right,
  CaseStudyContent,
  Background,
  Content,
  Citations,
} from "./style"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function GeneratedPage({ data }) {
  console.log(data)

  // const content = data.contentfulCaseStudy.caseStudyContent
  const shortDescription =
    data.contentfulCaseStudy.shortDescription.childMarkdownRemark.html
  const { title, thumbnail, citations } = data.contentfulCaseStudy
  const image = getImage(thumbnail)

  //console.log(JSON.parse(content.raw))

  const options = {
    renderMark: {},
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
            alt="Contentful post image"
            style={{ width: "100%", height: "600px" }}
            image={node?.data?.target?.gatsbyImageData}
          />
        )
      },
    },
  }

  return (
    <Layout>
      <Top>
        <Background>
          <StaticImage
            src="../../images/generated-page-background.png"
            alt="lines"
            placeholder="blurred"
            objectFit="fill"
            style={{
              width: "100%",
            }}
          />
        </Background>
        <Content>
          <Left>
            <GatsbyImage image={image} alt="alt" />
          </Left>
          <Right>
            <h2>{title}</h2>
            <div
              className="html-container"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            ></div>
          </Right>
        </Content>
      </Top>
      <CaseStudyContent>
        {data?.contentfulCaseStudy?.modularContent.map((el, idx) => {
          if (el?.contentField?.richTextContent) {
            return (
              <div key={idx} className="case-study__rich-text">
                {renderRichText(el?.contentField.richTextContent, options)}
              </div>
            )
          }
          if (el?.contentField?.image) {
            return (
              <GatsbyImage
                className="case-study__image"
                key={idx}
                image={el?.contentField?.image?.gatsbyImageData}
                objectFit="contain"
              />
            )
          }
          if (el?.leftColumnContent || el?.rightColumnContent) {
            return (
              <div key={idx} className="case-study__double-content">
                {el?.leftColumnContent?.richTextContent && (
                  <div className="case-study__left-column">
                    {renderRichText(
                      el?.leftColumnContent?.richTextContent,
                      options
                    )}
                  </div>
                )}
                {el?.leftColumnContent?.image && (
                  <GatsbyImage
                    className="case-study__left-column"
                    image={el?.leftColumnContent?.image?.gatsbyImageData}
                    objectFit="contain"
                  />
                )}
                {el?.rightColumnContent?.richTextContent && (
                  <div className="case-study__right-column">
                    {renderRichText(
                      el?.rightColumnContent.richTextContent,
                      options
                    )}
                  </div>
                )}
                {el?.rightColumnContent?.image && (
                  <GatsbyImage
                    className="case-study__right-column"
                    image={el?.rightColumnContent?.image?.gatsbyImageData}
                    objectFit="contain"
                  />
                )}
              </div>
            )
          }
        })}
      </CaseStudyContent>
      {/*
      <div>{renderRichText(content, options)}</div>
       
         <HtmlContainer dangerouslySetInnerHTML={{ __html: content }} />
        */}
      {citations ? (
        <Citations>
          {citations.map((citation, index) => (
            <Citation key={index} citation={citation} />
          ))}
        </Citations>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query CaseStudy($slug: String) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      title
      thumbnail {
        gatsbyImageData
      }

      citations {
        author
        content {
          raw
        }
      }
      modularContent {
        ... on ContentfulDoubleContent {
          id
          leftColumnContent {
            ... on ContentfulImageEntry {
              id
              image {
                gatsbyImageData
              }
            }
            ... on ContentfulRichTextEntry {
              id
              richTextContent {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    title
                    description
                    gatsbyImageData(width: 1000)
                    __typename
                  }
                }
              }
            }
          }
          rightColumnContent {
            id
            image {
              gatsbyImageData
            }
          }
        }
        ... on ContentfulSingleContent {
          id
          contentField {
            richTextContent {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  title
                  description
                  gatsbyImageData(width: 1000)
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`

/*

query MyQuery {
  contentfulCaseStudy {
    modularContent {
      ... on ContentfulDoubleContent {
        id
        leftColumnContent {
          ... on ContentfulImageEntry {
            id
            image {
              gatsbyImageData
            }
          }
          ... on ContentfulRichTextEntry {
            id
            richTextContent {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  title
                  description
                  gatsbyImageData(width: 1000)
                  __typename
                }
              }
            }
          }
        }
        rightColumnContent {
          id
          image {
            gatsbyImageData
          }
        }
      }
      ... on ContentfulSingleContent {
        id
        contentField {
          richTextContent {
            raw
            references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
          }
        }
      }
    }
  }
}

*/
