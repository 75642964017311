import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Content = styled.div`
  position: relative;
  padding: 0 20vw;
  margin: 0 auto;
  max-width: calc(1200px + 20vw);
  @media (max-width: 890px) {
    padding: 0 20vw;
  }
`

export const Background = styled.div`
  position: absolute;
  width: 100%;
  z-index: -1;
  top: -200px;
  right: 0;
  @media (max-width: 890px) {
    top: -50px;
    right: 0;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
`

export const Author = styled.h3`
  margin-top: 50px;
  @media (max-width: 890px) {
    margin-top: 30px;
  }
`

export const LeftQuotationMark = styled.div`
  position: absolute;
  bottom: -50px;
  left: -150px;
  @media (max-width: 890px) {
    bottom: 50px;
    left: -16vw;
    width: 50px;
  }
`

export const RightQuotationMark = styled.div`
  position: absolute;
  top: -60px;
  right: -175px;
  @media (max-width: 890px) {
    width: 50px;
    top: -30px;
    right: -16vw;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Paragraph = styled.p`
  text-align: center;
  font-style: italic;
  line-height: 1.8em;
  font-size: 18px;
`
