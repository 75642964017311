import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import {
  Container,
  Background,
  LeftQuotationMark,
  RightQuotationMark,
  Text,
  Content,
  Author,
  Wrapper,
  Paragraph,
} from "./style"

export const Citation = ({ citation }) => {
  const { content, author } = citation
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <Paragraph>{children}</Paragraph>
      },
    },
  }
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../images/citation-background.png"
          alt="lines"
          placeholder="blurred"
          objectFit="fill"
          style={{
            width: "100%",
          }}
        />
      </Background>
      <Content>
        <Wrapper>
          <LeftQuotationMark>
            <StaticImage
              src="../../images/quotation-mark.png"
              alt="quotation mark"
            />
          </LeftQuotationMark>
          <RightQuotationMark>
            <StaticImage
              src="../../images/quotation-mark.png"
              alt="quotation mark"
            />
          </RightQuotationMark>
          <Text>{renderRichText(content, options)}</Text>
          <Author>{author}</Author>
        </Wrapper>
      </Content>
    </Container>
  )
}
