import styled from "styled-components"

export const Top = styled.div`
  position: relative;
`

export const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1vw;
  position: relative;
  max-height: 400px;
  min-height: 350px;
  @media (max-width: 890px) {
    min-height: initial;
  }
`

export const ImageWrapper = styled.div`
  @media (max-width: 890px) {
    padding: 5vw;
  }
`

export const ImageBackground = styled.div`
  max-width: 400px;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Right = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`

export const CaseStudyContent = styled.div`
  margin: 70px auto 100px;
  padding: 0 10vh;
  max-width: calc(1400px + 10vh);

  & h3 {
    margin-bottom: 16px;
  }

  & h4 {
    margin: 40px 0 18px;
  }

  & p {
    margin-bottom: 18px;
  }

  & ul {
    margin-left: 24px;
    margin-bottom: 24px;
    list-style-type: none;
  }

  & ul > li {
    margin-bottom: 12px;
  }

  .case-study__rich-text {
  }

  .case-study__image {
  }

  .case-study__double-content {
    display: flex;
    flex-direction: row;
  }

  .case-study__left-column {
    width: 50%;
    margin: 24px 24px 24px 0px;
  }

  .case-study__right-column {
    width: 50%;
    margin: 24px 0px 24px 24px;
  }

  @media (max-width: 890px) {
    margin: 50px auto 80px;
    padding: 0 8vw;

    .case-study__double-content {
      flex-direction: column;
    }

    .case-study__left-column,
    .case-study__right-column {
      width: 100%;
      margin: 24px 0px;
    }
  }
`

export const Background = styled.div`
  width: 100%;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: -1;
`

export const Content = styled.div`
  margin: 50px auto 0;
  padding: 0 10vh;
  max-width: calc(1400px + 10vh);
  align-items: center;
  display: grid;
  grid-template-columns: 35% 60%;
  column-gap: 5%;

  & .html-container {
  }

  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    padding: 0 8vw;
    gap: 65px;
  }
`

export const Citations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin-bottom: 150px;
  @media (max-width: 890px) {
    margin-bottom: 30px;
  }
`
